import { makeStyles } from '@material-ui/core';
import React, { createContext, useCallback, useContext, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ResizeObserver from 'resize-observer-polyfill';
import { isSafari } from '../utils/isSafari';
import { WEB_CHAT_VERTICAL_MARGIN } from '../pages/web-chat/RightIndemWebChat';
import useLastActivity from '../pages/web-chat/hooks/useLastActivity';

const useStyles = makeStyles({
  root: {
    overflowY: 'auto',
    height: ({ textareaHeight }) => `calc(100vh - ${textareaHeight}px)`,
    display: 'flex',
    flexDirection: 'column',
  },
});

function ScrollToBottom({ children, sendBoxContainerRef, ...other }) {
  const lastActivity = useLastActivity();
  const { root } = useStyles({
    textareaHeight: lastActivity?.issendboxdisabled
      ? WEB_CHAT_VERTICAL_MARGIN * 2
      : (sendBoxContainerRef.current?.offsetHeight || 0) + WEB_CHAT_VERTICAL_MARGIN * 2,
  });

  const containerRef = useRef(null);
  const lastElRef = useRef(null);

  const prevContainerHeight = useRef(null);

  useEffect(() => {
    const el = containerRef.current;
    function onMutation() {
      if (lastElRef.current) {
        setTimeout(() => {
          lastElRef.current?.scrollIntoView();
        }, 0);
        if (isSafari) {
          setTimeout(() => {
            el.scrollTop = el.scrollHeight;
          }, 50);
        }
      }
    }

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const diff = prevContainerHeight.current - entry.contentRect.height;
        prevContainerHeight.current = entry.contentRect.height;
        if (diff > 0 && el.scrollHeight !== el.offsetHeight) {
          el.scroll({ top: diff + el.scrollTop });
        }
      }
    });

    const config = { childList: true, subtree: true };
    const observer = new MutationObserver(onMutation);

    if (el) {
      resizeObserver.observe(el);
      observer.observe(el, config);
    }

    return () => {
      resizeObserver.disconnect();
      observer.disconnect();
    };
  }, []);

  return (
    <ScrollToBottomProvider containerRef={containerRef}>
      <div ref={containerRef} className={root} {...other}>
        {children}
        <div ref={lastElRef} />
      </div>
    </ScrollToBottomProvider>
  );
}

export default ScrollToBottom;

const ScrollToBottomContext = createContext(null);
export const useScrollToBottom = () => useContext(ScrollToBottomContext);

function ScrollToBottomProvider({ children, containerRef }) {
  const scrollToBottom = useCallback(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = containerRef.current?.scrollHeight;
    }
  }, [containerRef]);

  return <ScrollToBottomContext.Provider value={scrollToBottom}>{children}</ScrollToBottomContext.Provider>;
}

ScrollToBottomProvider.propTypes = {
  children: PropTypes.node.isRequired,
  containerRef: PropTypes.object.isRequired,
};

ScrollToBottom.propTypes = {
  children: PropTypes.node.isRequired,
  sendBoxContainerRef: PropTypes.object.isRequired,
};
